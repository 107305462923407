<template>
  <div>
    <div>
      <div class="project-table">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">教务处排课导入</div>
          <div class="flex">
            <button class="addBtn" @click="downloadTemplate">下载模板</button>
            <button class="addBtn marginLeft10" @click="importScheduling">导入排课</button>
            <button class="addBtn marginLeft10" @click="batchUpdate">批量同步</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="customId" @selection-change="handleSelectionChange"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="55" />
          <el-table-column label="代码">
            <template #default="{ row }">
              {{ row.get('代码') }}
            </template>
          </el-table-column>
          <el-table-column prop="className" label="授课班级" />
          <el-table-column prop="laboratoryId" label="实验室">
            <template #default="{ row }">
              {{ row.get('地点') }}
            </template>
          </el-table-column>
          <el-table-column prop="laboratoryId" label="课程名称">
            <template #default="{ row }">
              {{ row.get('课程名称') }}
            </template>
          </el-table-column>
          <el-table-column prop="laboratoryId" label="教师姓名">
            <template #default="{ row }">
              {{ row.get('教师姓名') }}
            </template>
          </el-table-column>
          <el-table-column prop="laboratoryId" label="节次">
            <template #default="{ row }">
              {{ row.get('节次') }}
            </template>
          </el-table-column>
          <el-table-column prop="studentNum" label="学生人数" />
          <el-table-column prop="userType" label="用户类型" >
            <template #default>老师</template>
          </el-table-column>
          <el-table-column prop="laboratoryId" label="周次">
            <template #default="{ row }">
              {{ row.get('周次') }}
            </template>
          </el-table-column>
          <el-table-column prop="laboratoryId" label="星期">
            <template #default="{ row }">
              {{ row.get('星期') }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期" />
          <el-table-column fixed="right" label="操作">
            <template #default="{ row }">
              <div>
                <el-button link type="primary" @click="viewInfo(row)" style="color: #3769B9">查看</el-button>
                <el-button link type="primary" @click="writeInfo(row)" style="color: #3769B9">同步</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">

        </div>
      </div>
    </div>
    <el-dialog v-model="editImport"
               title="排课信息"
               class="info-div600"
               destroy-on-close
               style="padding: 24px;text-align: left;">
      <div>
        <div class="error">{{ currErrorMsg }}</div>
        <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
          <div style="height: 450px;overflow: auto;">
            <el-form v-if="currRow">
              <el-form-item v-for="iter in showInfoKeys" :key="iter" :label="iter+':'" size="large">
                <div>{{ currRow.get(iter) }}</div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="dialog-footer marginTop30px text-right">
          <button class="subBtn" @click="editImport = false">关闭</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getSystemTermStart, setJwData} from "@/api/api";
import {ExcelData} from "@/utils/scheduling";
import {readExcelByOne} from "@/utils/common";

export default {
  name: "schedulingList",
  data(){
    return {
      tableData:[],
      dataInfo:[],
      startingWeek: '',
      editImport: false,
      currRow: {},
      currErrorMsg: '',
      showInfoKeys: [
        '代码', '优选代码', '优选类别',
        '周次', '地点', '学分',
        '容量', '序号', '开课院系',
        '性质', '教学班号', '教室容量',
        '教师姓名', '教师工号', '星期',
        '校区', '职称', '节次',
        '课程名称', '选课人数', '选课编号', '院系代码',
      ],
      // 多条导入
      importDataSets: [],
    }
  },
  watch: {
    'editImport': {
      handler(val){
        if (!val){
          this.currErrorMsg = ''
        }
      }
    }
  },
  mounted() {
    getSystemTermStart().then(res => {
      if (res.success) {
        const data = res.data
        if (!data) {
          this.$message.error('请先联系管理员设置本学期开始日期!')
        } else {
          this.startingWeek = data.value
        }
      }
    })
  },
  methods: {
    // -e
    /**
     * 查看行
     */
    viewInfo(row){
      this.currRow = row
      this.editImport = true
    },
    /**
     * 同步单条数据
     */
    writeInfo(row){
      const { adopt, message } = row.verifyData()
      if (!adopt) {
        this.currErrorMsg = message
        this.viewInfo(row)
        return
      }
      // 提交
      const submitHandler = () => {
        const loading = this.$loading({ fullscreen: true })
        const params = row.getParams()
        setJwData([ params ]).then(res => {
          if (res.success) {
            this.$message.success('同步成功!')
          } else {
            this.$message.error(res.message)
          }
          loading.close()
        })
      }

      this.$confirm('确认同步1条数据到实验室预约吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        submitHandler()
      })
    },
    /**
     * 批量更新
     */
    batchUpdate() {
      if (this.importDataSets.length === 0) {
        this.$message.warning('请选择排课信息!')
        return
      }

      // 检测
      const params = []
      for (let importDataSet of this.importDataSets) {
        const { adopt, message } = importDataSet.verifyData()
        if (!adopt) {
          this.currErrorMsg = message
          this.viewInfo(importDataSet)
          return
        }
        params.push(importDataSet.getParams())
      }

      // 提交
      this.$confirm(`确认同步${this.importDataSets.length}条数据到实验室预约吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        setJwData(params).then(res => {
          if (res.success) {
            this.$message.success('同步成功!')
          } else {
            this.$message.error(res.message)
          }
          loading.close
        })
      })
    },
    /**
     * 下载模板
     */
    downloadTemplate(){
      let a = document.createElement('a')
      a.href = `/resource/吉利学院讲节次课表导出(2023-2024第2学期).xls`
      a.download = '吉利学院讲节次课表导出(2023-2024第2学期).xls'
      a.click()
    },
    /**
     * 导入排课
     */
    importScheduling(){
      const selectExcel = (eh) => {
        const existenceDom = document.getElementById('fileExcel')

        if (existenceDom) {
          const parent = existenceDom.parentNode
          parent.removeChild(existenceDom)
        }

        let fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.id = 'fileExcel'
        fileInput.style.display = 'none'
        fileInput.accept = '.xlsx, .xls'
        // 为文件输入元素添加事件监听器
        fileInput.addEventListener('change', eh)
        document.body.appendChild(fileInput)

        const dom = document.getElementById('fileExcel')
        if (dom) {
          dom.click()
        }
      }

      const excelHandler = e => {
        const file = e.target.files[0]
        // 读取excel第一页
        readExcelByOne(file).then(ws => {
            // console.log(ws, '---------ws-');
            //
            new ExcelData(ws.slice(1), d => {
              this.tableData = d
              this.changeStartingWeek()
            })
        }).catch(() => {
          this.$message.error('文件加载失败, 请检查表格是否正确')
        })
      }

      //
      this.tableData = []
      //
      selectExcel(excelHandler)
    },
    /**
     * 修改开始周
     */
    changeStartingWeek(){
      if (!this.startingWeek) {
        this.$message.error('请先联系管理员设置本学期开始日期!')
        return
      }
      this.tableData.forEach(iter => {
        iter.generationDate(this.startingWeek)
      })
    },
    /**
     * 多选
     * @param e
     */
    handleSelectionChange(e){
      this.importDataSets = e
    },
  }
}
</script>

<style scoped lang="scss">

</style>